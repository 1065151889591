import {AfterViewChecked, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServerErrors} from '../../helpers/server-errors';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormHelper} from '../../helpers/form.helper';
import {UserService} from '../../../../services/user.service';
import {LoginResponse} from '../../../../entity/login-response';
import {CookieService} from 'ng2-cookies';
import {ForgotPasswordComponent} from '../forgot-password/forgot-password.component';
import {AudioModalComponent} from '../modals/audio-modal/audio-modal.component';
import {Router} from '@angular/router';
import {BirthDate} from '../../../../entity/birth-date';
import {PaymentService} from '../../../customer/services/payment.service';
import {AlertService} from '../../../../services/alert.service';
import {AllEmiterService} from '../../../../services/all-emiter.service';
import {SubSink} from 'subsink';
import {BackRouter} from '../../services/back-router';
import {environment} from '../../../../../environments/environment';
import {GoogleAnalyticsEventsService} from '../../../../services/google-analytics-events.service';
import {SCREEN_SIZE} from '../../../../screen-size.enum';
import {ResizeService} from '../../../../services/resize.service';
import {ConsultantService} from '../../../consultant/services/consultant.service';
import {UtilService} from '../../../../services/util.service';
import {WINDOW} from '../../../../providers/window.provider';
import {AuthNestjsService} from '../../../../services/nestjs/auth/auth-nestjs.service';
import {NGXLogger} from 'ngx-logger';
import {DomSanitizer} from '@angular/platform-browser';
import {SignInWithGoogleRequest} from '../../entity/signin-with-google.request';
import {SocialLoginResponse} from '../../../../entity/social-login-response';
import { Location } from '@angular/common';
import {SignInWithAppleRequest} from '../../entity/signin-with-apple.request';

declare var FB: any;
declare let google: any;
declare var AppleID: any;

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends ServerErrors implements OnInit, AfterViewChecked, OnDestroy {
    constructor(public bsModalRef: BsModalRef,
                private fb: FormBuilder,
                private userService: UserService,
                private cookie: CookieService,
                private bsModalService: BsModalService,
                private router: Router,
                private paymentService: PaymentService,
                private alertService: AlertService,
                private br: BackRouter,
                // private atmosphereService: AtmosphereService,
                private allEmiterService: AllEmiterService,
                private gAE: GoogleAnalyticsEventsService,
                private resizeSvc: ResizeService,
                private utilService: UtilService,
                private consultantService: ConsultantService,
                private authService: AuthNestjsService,
                private logger: NGXLogger,
                private sanitizer: DomSanitizer,
                private location: Location,
                @Inject(WINDOW) private window: Window) {
        super();
    }
    form: FormGroup;
    errorMessage: string;
    doSignIn: EventEmitter<boolean> = new EventEmitter();
    doRegister: EventEmitter<boolean> = new EventEmitter();
    hasLoggedUsingFacebook = undefined;
    error;
    private subs = new SubSink();
    size: SCREEN_SIZE;
    facebookLoaded = false;
    googleLoaded = false;
    /**
     * To know in which step the user is during login
     * 1. 2FA no requested
     * 2. 2FA requested
     */
    step = '1';
    sending2FA = false;
    @ViewChild('googleButtonContainer') googleButtonContainer: ElementRef;

    protected readonly undefined = undefined;


    ngOnInit() {
        // this.step = localStorage.getItem('login-step');
        //
        // if (this.step === null) {
        //     this.step = '1';
        // }
        // this.logger.debug('Do login step', this.step);
        const self = this;
        this.buildForm();
        self.facebookLoginStatus();
        this.subs.sink = this.resizeSvc.onResize$.subscribe(x => {
            this.size = x;
        });
    }

    ngAfterViewInit() {
        this.loadGoogleSignIn();
    }

    loadGoogleSignIn() {
        if (!environment.electron) {
            const authButton = this.googleButtonContainer.nativeElement;
            try {
                google.accounts.id.initialize({
                    client_id: environment.google.auth.client_id,
                    callback: (response: any) => {
                        const decodedJwt = this.decodeJwtToken(response.credential);

                        const signInDto = new SignInWithGoogleRequest(
                            decodedJwt.payload.email,
                            decodedJwt.payload.family_name,
                            decodedJwt.payload.given_name,
                            decodedJwt.payload.sub,
                            decodedJwt.payload.picture,
                            decodedJwt.payload.name,
                            this.router.url,
                            'lifereader-' + environment.name,
                            false,
                            btoa(navigator.userAgent),
                            response.credential,
                            response.credential,
                            response.credential
                        );

                        this.subs.sink = this.userService.doLoginGoogle(signInDto).subscribe(async (loginResponse: SocialLoginResponse) => {
                            try {
                                if (!loginResponse.newAccount) {
                                    await this.router.navigate([this.router.url]);
                                } else {
                                    this.addUrlParameter('registration', 'true');
                                    this.utilService.localStorageSetItem('registration', 'step2');
                                }
                                await this.processLoginData(loginResponse as unknown as LoginResponse);
                            } catch (e) {
                                console.error(e);
                            }
                        },
                        (err2: any) => {
                            if (err2.status === 400) {
                                this.error = err2?.error?.message ?? 'Message does not exits';
                            }
                        });
                    }
                });

                google.accounts.id.renderButton(authButton, { theme: 'filled_black', type: 'icon' });
                this.googleLoaded = true;
            } catch (e) {
                console.error(e);
                this.utilService.setTimeout(() => {
                    this.loadGoogleSignIn();
                }, 500);
            }
        }
    }

    async loadAppleSignIn() {
        try {
            AppleID.auth.init({
                clientId: 'com.lifereader.app.email.web.services',
                redirectURI: window.location.origin,
                scope: 'name email',
                state: 'init',
                usePopup: true,
            });
            const data = await AppleID.auth.signIn();
            const decodedPayload = this.decodeJwtToken(data.authorization.id_token);
            const decodedToken = decodedPayload.payload;
            const userName = data.user;
            const signInDto = new SignInWithAppleRequest(
                decodedToken.sub,
                decodedToken.email,
                userName?.name?.firstName || null,
                userName?.name?.lastName || null,
                data.authorization.id_token,
                data.authorization.code,
                null,
                null,
                false,
                'lifereader-' + environment.name
            );
            this.subs.sink = this.userService.doLoginApple(signInDto).subscribe(async (loginResponse: SocialLoginResponse) => {
                    try {
                        if (!loginResponse.newAccount) {
                            await this.router.navigate([this.router.url]);
                        } else {
                            this.addUrlParameter('registration', 'true');
                            this.utilService.localStorageSetItem('registration', 'step2');
                        }
                        await this.processLoginData(loginResponse as unknown as LoginResponse);
                    } catch (e) {
                        console.error(e);
                    }
                },
                (err2: any) => {
                    if (err2.status === 400) {
                        this.error = err2?.error?.message ?? 'Message does not exits';
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }

    facebookLoginStatus() {
        if (!environment.electron) {
            const self = this;
            // Check if user has logged in previously
            try {
                FB.getLoginStatus(function (response) {
                    self.facebookLoaded = true;

                    if (response.status === 'connected') {
                        self.hasLoggedUsingFacebook = true;
                    } else {
                        self.hasLoggedUsingFacebook = false;
                    }
                });
            } catch (e) {
                console.error(e);
                // try again
                self.utilService.setTimeout(function () {
                    self.facebookLoginStatus();
                }, 500);

            }
        }
    }

    ngAfterViewChecked() {
    }


    async getOffers() {
        try {
            await this.paymentService.getPromotionsRegistrationCC().toPromise();
            await this.paymentService.getPromotionsRegistrationPaypal().toPromise();
        } catch (e) {
            console.error(e);
            console.error('Error getting offers');
        }
    }

    async getLastPaymentSystemUsed() {
        try {
            await this.paymentService.getLastPaymentSystemUsed().toPromise();
        } catch (e) {
            console.error(e);
            console.error('Error getting last payment system');
        }
    }

    getDataFacebook() {
        if (!environment.electron) {
            const self = this;
            // FB.login();
            FB.getLoginStatus(function (response) {
                if (response.status === 'connected') {
                    FB.api(
                        '/me?fields=id,name,gender,about,address,age_range,birthday,email,education,' +
                        'first_name,last_name,location,relationship_status,short_name,work',
                        function (response2) {
                            self.processFacebookData(response2);
                        },
                    );
                    return;
                } else {
                    self.loginFacebook();
                }
            });
        }
    }

    loginFacebook() {
        if (!environment.electron) {
            const self = this;
            FB.login(function (response) {
                if (response.authResponse) {
                    /* make the API call */
                    FB.api(
                        '/me?fields=id,name,gender,about,address,age_range,birthday,email,education,' +
                        'first_name,last_name,location,relationship_status,short_name,work',
                        function (response2) {
                            self.processFacebookData(response2);
                        },
                    );
                    return;
                }

            }, {scope: 'email'});
        }
    }

    processFacebookData(response2) {
        const referralKey = '';
        const self = this;
        // tslint:disable-next-line:no-bitwise
        const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
        if (response2 && !response2.error) {
            let birthday = response2.birthday;
            const dob: BirthDate = new BirthDate();
            if (birthday) {
                birthday = birthday.split('/');
                try {
                    const day: number = parseInt(birthday[0], 10);
                    dob.day = day;
                } catch (e) {
                    dob.day = 1;
                }
                try {
                    const month: number = parseInt(birthday[1], 10);
                    dob.month = month;
                } catch (e) {
                    dob.month = 1;
                }
                try {
                    const year: number = parseInt(birthday[2], 10);
                    dob.year = year;
                } catch (e) {
                    dob.year = 1991;
                }
            }
            if (dob.month > 12) {
                const aux = dob.month;
                dob.month = dob.day;
                dob.day = aux;
            }
            response2.name = response2.name.split(' ')[0] + ' ' + hashCode(response2.last_name);
            this.subs.sink = self.userService.doLoginFB({
                email: response2.email,
                lastName: response2.last_name,
                firstName: response2.first_name,
                gender: response2.gender + ' ',
                dob: dob,
                displayName: response2.name,
                referralKey,
                app: false
            }).subscribe(async (loginResponse: LoginResponse) => {
                    try {
                        await this.router.navigate([this.router.url]);
                        await self.processLoginData(loginResponse);
                    } catch (e) {
                        console.error(e);
                    }
                },
                (res: any) => {
                    // This it can means that I have not register yet using facebook, so I have to continue the process
                    this.subs.sink = self.userService.doLoginFB({
                        email: response2.email,
                        lastName: response2.last_name,
                        firstName: response2.first_name,
                        gender: response2.gender + ' ',
                        dob: dob,
                        displayName: response2.name,
                        referralKey,
                        app: false
                    }).subscribe(async (loginResponse: LoginResponse) => {
                            try {
                                this.addUrlParameter('registration', 'true');
                                this.utilService.localStorageSetItem('registration', 'step2');
                                await self.processLoginData(loginResponse);
                            } catch (e) {
                                console.error(e);
                            }

                        },
                        (err2: any) => {
                            if (err2.status === 400) {
                                this.error = err2?.error?.message ?? 'Message does not exits';
                            }
                        });
                });
        }
    }

    async processLoginData(loginResponse: LoginResponse) {
        const self = this;
        if (loginResponse) {
            self.cookie.set('JSESSIONID', loginResponse.jsessionId);
            self.cookie.set('SPRING_SECURITY_REMEMBER_ME_COOKIE', loginResponse.rememberMe);
            self.utilService.localStorageSetItem('token', loginResponse.token);
            // LA-645 Set item so next time user reload the page we check if cc expired
            self.utilService.localStorageSetItem('checkCC', 'true');
            self.allEmiterService.onLoggedIn();
            self.bsModalRef.hide();
            if (self.userService.getRole() === 'ROLE_CUSTOMER') {
                await self.getOffers();
                await self.getLastPaymentSystemUsed();
                this.allEmiterService.onFundChange(0);
            }

            if (self.userService.getRole() === 'ROLE_READER') {
                self.bsModalService.show(AudioModalComponent, {
                    class: 'modal-sm',
                    backdrop: 'static',
                    keyboard: false,
                    ignoreBackdropClick: true,
                    animated: false,
                });
                // Call web service to update their status to the same they have,
                // so the server can send an email in case user restore session
                self.subs.sink = this.consultantService.sendEmail().subscribe(() => {});
            }

            if (self.userService.getRole() === 'ROLE_ADMIN') {
                // await self.router.navigate(['/admin']);
                this.window.location.replace(this.br.backEndServer() + '/admin/livestats');
                return;
            }

            try {
                self.subs.sink = this.userService.getDetails().subscribe(() => {
                    if (this.userService.isLoggedIn()) {
                        // Reload the page to avoid double connection to socket io
                        self.window.location.reload();
                        // self.atmosphereService.connect();
                    }
                }, (e) => {
                    if (e.message !== 'No user data') {
                        throw e;
                    }
                });
            } catch (e) {
                console.error(e);
            }
        } else {
            throw new Error('No user data');
        }
    }

    buildForm() {
        this.form = this.fb.group({
            email: ['', Validators.compose([
                Validators.required,
            ])],
            password: ['', Validators.compose([
                Validators.required,
            ])],
            'remember-me': [true],
            app: [false]
        });
    }

    getImage() {
        return this.br.backendUrl + '/2016/static/img/comodo-logo.png';
    }

    doRegistration() {
        this.bsModalRef.hide();
        this.doRegister.emit(true);
        // this.registrationModalRef = this.modalService.show(RegistrationComponent);
    }
    async goToSupport() {
        this.bsModalRef.hide();
        this.bsModalRef.hide();
        await this.router.navigate(['/contact']);

    }


    doSubmit() {
        this.errorMessage = '';
        const self = this;
        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            // return;
        }
        // submit data
        // @ts-ignore
        this.subs.sink = this.userService.doLogin({
            email: this.form.value.email,
            password: this.form.value.password,
            rememberMe: this.form.controls['remember-me'].value,
            app: false,
        }).subscribe(async (response: LoginResponse) => {
                try {
                    await self.processLoginData(response);
                    await self.router.navigate([this.router.url]);
                } catch (e) {
                    console.error(e);
                }

            },
            (res: any) => {
                this.errorMessage = res.error?.message ?? 'Message does not exits';
                if (res.error.additionalFields) {
                    const messageErrosArr = this.errorMessage.split(',').map(obj => obj.split('=')).map(obj => obj.filter((el, i) => i));
                    this.errorMessage = [].concat.apply([], messageErrosArr).join(', ');
                }
                if (res.error?.message?.includes('Invalid credentials.') ?? ' ') {
                    this.errorMessage = 'Sorry Incorrect login or password';
                }
                if (res.error?.message === '2FA Code not valid') {
                    this.alertService.alert({
                        body: 'Verification code incorrect',
                        type: 'info',
                        title: 'LifeReader',
                    });
                }
                if (res.error.additionalFields === null) {
                    return;
                }
                Object.entries(res.error.additionalFields).forEach(([key, value]) => {
                    this.setServerError(key, value);
                });
                // return true;
            });
    }

    openModalForgotPassword() {
        this.bsModalRef.hide();
        this.bsModalService.show(ForgotPasswordComponent, {class: 'modal-sm', animated: false, });
    }

    doLogin() {
        // this.bsModalRef.hide();
        this.doSignIn.emit(true);
    }

    decodeJwtToken(token: string) {
        try {
            const tokenParts = token.split('.');
            const header = JSON.parse(this.b64DecodeUnicode(tokenParts[0]));
            const payload = JSON.parse(this.b64DecodeUnicode(tokenParts[1]));
            const signature = tokenParts[2];
            return {header, payload, signature};
        } catch (e) {
            console.error(e);
        }
    }

    addUrlParameter(paramKey: string, paramValue: string) {
        // Get the current URL
        const currentUrl = this.location.path();

        // Create a URL object to manipulate the query parameters
        const url = new URL(window.location.origin + currentUrl);
        url.searchParams.set(paramKey, paramValue);

        // Use the Location service to change the URL without navigation
        this.location.replaceState(url.pathname + url.search);
    }

    b64DecodeUnicode(str: any) {
        return decodeURIComponent(atob(str).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    // changeInput() {
    //     this.form.controls['remember-me'].setValue(!this.form.controls['remember-me'].value);
    // }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
